<template>
  <div class="d-flex align-items-center" style="height:calc(100% - 70px)">
    <b-container>
      <b-row align-h="center">
        <b-col xl="5" cols="8">
          <label class="text-left">
            使用者名稱
            <b-form-input type="text" v-model="form.email"></b-form-input>
          </label>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col xl="5" cols="8">
          <label class="text-left">
            密碼
            <b-form-input
              type="password"
              v-model="form.password"
            ></b-form-input>
          </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-btn
            size="lg"
            variant="primary"
            @click="act(form.email, form.password)"
            >登入</b-btn
          >
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { ACTION } from "../store/types";
export default {
  name: "Login",
  data() {
    return {
      form: {
        email: "",
        password: ""
      }
    };
  },
  methods: {
    act(_email, _password) {
      this.$store
        .dispatch(ACTION.login, {
          _email: _email,
          _password: _password
        })
        .then(() => this.$router.push({ name: "HistoryEvent" }));
    }
  }
};
</script>
